// import { Image } from 'gatsby-image'
import styled from 'react-emotion'

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  & .gatsby-image-outer-wrapper,
  & .gatsby-image-wrapper {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
  }
`

export default ImageContainer
