import React, { Fragment } from 'react'
import {Link, graphql} from 'gatsby'
import styled from 'react-emotion'
import Img from 'gatsby-image'
import ImageContainer from '../components/common/ImageContainer'
import PageTitle from '../components/common/PageTitle'
import tinycolor from 'tinycolor2'
import BlogLayout from "../layouts/blog";

import rightArrow from '../assets/images/icon-right-arrow.svg'

const PageContent = styled.div`
  color: #4a4a4a;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 960px) {
    & p {
      font-size: 1.2rem;
      line-height: 1.5;
      margin-bottom: 1.2rem;
    }
  }
`

const PageButton = styled(Link)`
  font-family: ${props => props.theme.headerFont};
  text-decoration: none;
  background-color: ${props => props.theme.purple};
  box-shadow: 0px ${props => props.theme.paddingSize / 10}px 0px 0px
    ${props => props.theme.red};
  border-radius: 10px;
  padding: ${props => props.theme.paddingSize / 3.5}px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${props => props.theme.paddingSize}px;
  text-align: center;
  position: relative;

  a& {
    color: ${props => props.theme.yellow};
    border-bottom: 0;
  }

  &:hover {
    background-color: ${props =>
      tinycolor(props.theme.purple)
        .lighten()
        .toHexString()};
  }
`

const ChapterComponent = props => {
  const { chapter, page } = props.data;
  return (
    <BlogLayout location={props.location}>
      <Fragment>
        {chapter && chapter.background && chapter.background.fixed ? (
          <ImageContainer>
            <Img fixed={chapter.background.fixed} alt={chapter.title} />
          </ImageContainer>
        ) : null}
        {chapter ? <PageTitle>{chapter.title}</PageTitle> : null}
        {chapter && chapter.subtitle ? (
          <PageContent
            dangerouslySetInnerHTML={{
              __html: chapter.subtitle.childMarkdownRemark.html,
            }}
          />
        ) : null}
        {chapter && page ? (
          <PageButton to={`/${chapter.slug}/${page.path}/`}>
            <span>{`Chapter ${chapter.chapterNumber}: ${page.title}`}</span>
            <img css={{ marginBottom: 0, marginLeft: 10 }} src={rightArrow} alt="next page"/>
          </PageButton>
        ) : null}
      </Fragment> 
    </BlogLayout>
  )
}

export default ChapterComponent

export const query = graphql`
  query ChapterQuery($slug: String!, $firstPage: String) {
    chapter: contentfulChapter(slug: { eq: $slug }) {
      title
      slug
      chapterNumber
      subtitle {
        childMarkdownRemark {
          html
        }
      }
      background {
        fixed(width: 1920, height: 1080, quality: 50) {
          src
          width
          height
          srcSet
        }
      }
    }
    page: contentfulPage(path: { eq: $firstPage }) {
      path
      title
    }
  }
`
